import * as React from 'react'

function Search(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' fill='currentColor' {...props}>
      <path d='M18.134 17.135l-.96.966L22.038 23l.96-.967-4.865-4.898zm-.48.483l4.866 4.899-4.867-4.899zM18.902 14.503c.596-1.27.9-2.63.9-4.039 0-5.218-4.217-9.464-9.4-9.464C5.218 1 1 5.246 1 10.464s4.218 9.464 9.402 9.464c1.411 0 2.77-.308 4.037-.917l.106-.05 4.307-4.351.05-.107zm-4.756 3.89a8.622 8.622 0 01-3.744.849c-4.817 0-8.722-3.93-8.722-8.778 0-4.848 3.905-8.78 8.722-8.78 4.816 0 8.722 3.932 8.722 8.78 0 1.341-.3 2.611-.834 3.747l-4.143 4.182z' />
      <path d='M10.402 1.684c-4.817 0-8.722 3.932-8.722 8.78 0 4.848 3.905 8.78 8.722 8.78 1.34 0 2.61-.306 3.745-.848l4.14-4.183a8.773 8.773 0 00.835-3.747c0-4.85-3.904-8.782-8.72-8.782zm7.32 12.128l-3.972 4.011a7.906 7.906 0 01-3.348.735c-4.436 0-8.043-3.631-8.043-8.094 0-4.465 3.607-8.096 8.043-8.096 4.436 0 8.042 3.63 8.042 8.096a8.085 8.085 0 01-.723 3.348z' />
    </svg>
  )
}

export default Search
