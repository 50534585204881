import * as React from 'react'

function Like(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={36} height={30} viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M32.4 11H24V4.889C24 2.185 21.855 0 19.2 0h-2.4c-.6 0-1.11.443-1.185 1.054l-.435 3.147C14.76 7.09 13.035 9.61 10.5 11H1.2c-.66 0-1.2.55-1.2 1.222v17.111c0 .673.54 1.223 1.2 1.223h9.24l.78.534A10.958 10.958 0 0017.415 33H31.2c1.98 0 3.6-1.635 3.6-3.667 0-.901-.33-1.772-.915-2.444a3.7 3.7 0 000-4.889c1.035-1.161 1.215-2.872.45-4.247 1.68-1.085 2.16-3.361 1.095-5.057A3.579 3.579 0 0032.4 11zm-30 2.444h7.2v14.667H2.4V13.444zm30 2.445h-1.2c-.66 0-1.2.55-1.2 1.222 0 .672.54 1.222 1.2 1.222.66 0 1.2.55 1.2 1.223 0 .672-.54 1.222-1.2 1.222-.66 0-1.2.55-1.2 1.222 0 .672.54 1.222 1.2 1.222.66 0 1.2.55 1.2 1.222 0 .673-.54 1.223-1.2 1.223-.66 0-1.2.55-1.2 1.222 0 .672.54 1.222 1.2 1.222.66 0 1.2.55 1.2 1.222 0 .673-.54 1.223-1.2 1.223H17.415c-1.725 0-3.42-.52-4.86-1.498L12 28.676V12.94a11.67 11.67 0 005.55-8.402l.3-2.094h1.365c1.32 0 2.4 1.1 2.4 2.445v7.333c0 .672.54 1.222 1.2 1.222h9.6c.66 0 1.2.55 1.2 1.223 0 .672-.555 1.222-1.215 1.222z'
        fill='#66FCF1'
      />
    </svg>
  )
}

export default Like
