import { Box, Divider, Text } from '@chakra-ui/layout'
import { ChakraProps } from '@chakra-ui/system'
import { IconButton } from '@chakra-ui/button'
import React, { useEffect, useRef, useState } from 'react'
import { KeywordGroup, MenuValue } from '../../types'
import { AppContainer } from '../AppContainer'
import { Link } from '../Link'
import Search from '../../icons/Search'
import Image from 'next/image'
import { Column, Columns } from '../Columns'
import { Input, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react'
import router from 'next/router'
import { fetchGlobalSettings } from '../../fetchers/home'

export type MainNavItemProps = ChakraProps &
  React.LiHTMLAttributes<HTMLLIElement> & {
    url: string
    name: string
  }

export const MainNavItem = (props: MainNavItemProps) => {
  const { url, name, ...otherProps } = props
  return (
    <Box as='li' {...(otherProps as any)} display='inline-flex' py='9px' userSelect='none'>
      <Link
        href={url}
        onDragStart={e => {
          e.preventDefault()
        }}
      >
        <Text as='span' textStyle='navLink' textTransform='uppercase'>
          {name}
        </Text>
      </Link>
    </Box>
  )
}

export type MainNavProps = {
  list: { [key: string]: MenuValue }
  onOpenMenu?: () => void
}

export function MainNav(props: MainNavProps) {
  const { list = [] } = props
  const listRef = useRef<HTMLUListElement>()
  const mainNavRef = useRef<HTMLElement>()
  const searchFieldRef = useRef<HTMLInputElement>()

  const [keyword, setKeyword] = useState<string>('')

  const [loadingKeywords, setLoadingKeywords] = useState<boolean>(false)
  const [searchKeywords, setSearchKeywords] = useState<KeywordGroup>([])

  const [highlightText, setHighlightText] = useState<string>('')

  const [isSearchOpen, setIsSearchOpen] = useState(false)

  const handleLoadSearchKeywords = () => {
    setLoadingKeywords(true)
    fetchGlobalSettings().then(data => {
      if (data && data.searchKeywords && data.searchKeywords.length) {
        setSearchKeywords(data.searchKeywords)
      }

      if (data && data.navHighlight) {
        setHighlightText(data.navHighlight)
      }
      setLoadingKeywords(false)
    })
  }

  useEffect(() => {
    handleLoadSearchKeywords()
  }, [])

  const menus = Object.keys(list).map(m => {
    const menu = list[m]
    return {
      name: menu.title,
      url: menu.link
    }
  })

  const changeSearchVal = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value)
  }

  const handleDoSearch = (e: React.FormEvent) => {
    if (!keyword) {
      return
    }
    e.preventDefault()
    router.push(`/search/${encodeURI(keyword)}`)
  }

  // Close search popover when clicking outside, window scroll or resize
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (mainNavRef.current && !mainNavRef.current.contains(event.target as Node)) {
        setIsSearchOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [mainNavRef])

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleDoSearch(e)
    }
  }

  const handleOnPopoverOpen = () => {
    if (searchFieldRef.current) {
      searchFieldRef.current.focus()
    }
  }

  useEffect(() => {
    if (isSearchOpen) {
      setTimeout(() => {
        handleOnPopoverOpen()
      }, 100)
    }
  }, [isSearchOpen])

  return (
    <Box className='main-nav' width='100%' as='nav' bg='bg' ref={mainNavRef as any}>
      <AppContainer
        pt={0}
        pb={0}
        pl={{ base: '16px', md: '32px', xl: '85px' }}
        pr={{ base: '16px', md: '32px', xl: '85px' }}
      >
        <Divider className='main-nav-border' borderColor='border' />
        <Box display='flex' width='100%' alignItems='center' borderY='1px solid #66FCF1' mb='10px'>
          <Box className='main-nav-stick-menu' alignItems='center' py='10px' pr='18px'>
            <Link href='/' width='106px'>
              <Image
                src='https://static.letschuhai.com/static/images/logo.png'
                alt='36氪出海'
                width={106}
                height={24}
              />
            </Link>
          </Box>
          <Columns flex='1' collapse='lg' gutter='0px'>
            <Column fWidth='65%' flex={1} position='relative' zIndex='1' paddingLeft={'12px'}>
              <Box
                flex='1'
                ref={listRef as any}
                as='ul'
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  listStyleType: 'none',
                  whiteSpace: 'nowrap',
                  overflowY: 'hidden',
                  overflowX: 'hidden',
                  marginBottom: '-20px',
                  paddingBottom: '20px',
                  paddingRight: '2px',
                  position: 'relative',
                  zIndex: '9'

                  // '& > li:not(:first-of-type)': {
                  //   marginLeft: '12px'
                  // }
                }}
              >
                {menus.map((l, index) => {
                  if (highlightText && highlightText === l.name) {
                    return (
                      <Box as='li' display='inline-flex' py='9px' userSelect='none' key={index}>
                        <Link
                          href={l.url}
                          onDragStart={e => {
                            e.preventDefault()
                          }}
                          className='nav-highlight'
                        >
                          <Text as='span'>{l.name}</Text>
                        </Link>
                      </Box>
                    )
                  }
                  return (
                    <React.Fragment key={index}>
                      <Box textStyle='navLink'>/</Box>
                      <MainNavItem name={l.name} url={l.url} />
                    </React.Fragment>
                  )
                })}
              </Box>
            </Column>
            <Column fWidth={'35%'} maxW={'400px'} paddingLeft={'32px'} position={'relative'}>
              <Box
                as='form'
                mx='auto'
                onSubmit={handleDoSearch}
                display={'flex'}
                width={'100%'}
                alignItems={'center'}
                autoComplete='off'
              >
                <Popover {...props} isOpen={isSearchOpen} matchWidth>
                  <PopoverTrigger>
                    <Input
                      ref={searchFieldRef}
                      // pr='40px'
                      aria-label='Search'
                      variant='borderlessDesktopHeader'
                      name='nav-search'
                      placeholder='请输入关键词'
                      height={'32px'}
                      bg={'rgba(102,252,241,0.1)'}
                      borderRadius={'20px'}
                      fontSize={'13px'}
                      _hover={{
                        borderColor: '#FFF'
                      }}
                      _placeholder={{
                        color: 'rgba(255,255,255,0.75)'
                      }}
                      style={{ color: '#fff', WebkitTextFillColor: '#fff !important' }}
                      onChange={changeSearchVal}
                      _autofill={{
                        WebkitBoxShadow: '0 0 0 1000px #fff inset',
                        WebkitTextFillColor: '#fff !important',
                        color: '#fff'
                      }}
                      _focus={{
                        color: '#fff',
                        WebkitTextFillColor: '#fff !important'
                      }}
                      onKeyDown={handleKeyDown}
                      onFocus={() => {
                        setTimeout(() => {
                          if (!isSearchOpen) {
                            setIsSearchOpen(true)
                          }
                        }, 100)
                      }}
                      autoComplete='off'
                    />
                  </PopoverTrigger>
                  <PopoverContent
                    bg='bg'
                    boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
                    borderRadius='4px'
                    zIndex='100'
                    display={loadingKeywords ? 'none' : searchKeywords.length ? 'block' : 'none'}
                    width={'100%'}
                    border={'none'}
                    background={'#20353a'}
                  >
                    <Box as='ul' width='100%' listStyleType={'none'} p={'8px 0'}>
                      <Box as='li'>
                        <Text
                          textStyle='navLink'
                          lineHeight={'32px'}
                          textAlign={'left'}
                          color='primary'
                          p={'0 16px'}
                          opacity={0.7}
                        >
                          热门搜索
                        </Text>
                      </Box>
                      {searchKeywords.map((keyword, index) => {
                        return (
                          <Box as='li' key={index} display={'block'}>
                            <Link
                              href={keyword.url}
                              textStyle='navLink'
                              display={'block'}
                              textAlign={'left'}
                              _hover={{
                                color: 'white',
                                backgroundColor: '#1a202c'
                              }}
                              lineHeight={'32px'}
                              p={'0 16px'}
                            >
                              {keyword.text}
                            </Link>
                          </Box>
                        )
                      })}
                    </Box>
                  </PopoverContent>
                </Popover>
                <IconButton
                  onClick={handleDoSearch}
                  fontSize='20px'
                  textStyle={'navLink'}
                  variant='svg'
                  aria-label='Search'
                >
                  <Search />
                </IconButton>
              </Box>
            </Column>
          </Columns>
        </Box>
      </AppContainer>
    </Box>
  )
}
