import React from 'react'
import { InputProps as InputCoreProps, Input as InputCore } from '@chakra-ui/input'

export type InputProps = InputCoreProps & { ref?: any }

export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { ...otherProps } = props
  return <InputCore ref={ref} {...otherProps} />
})

Input.displayName = 'Input'
