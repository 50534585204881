import * as React from 'react'

function Share(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='16px' height='14px' viewBox='0 0 16 14' fill='currentColor' {...props}>
      <path
        d='M0.533333333,13.8665976 C0.492666667,13.8665976 0.4516,13.8619309 0.410933333,13.8525976 C0.170266667,13.7952643 0,13.5805976 0,13.3332643 C0,8.48686427 0.613066667,4.4561976 8,4.27286427 L8,0.533264271 C8,0.321264271 8.12546667,0.129664271 8.31933333,0.0447309375 C8.51253333,-0.0396690625 8.73906667,-0.00313572919 8.89533333,0.141664271 L15.8286667,6.54166427 C15.938,6.64206427 16,6.78433094 16,6.93326427 C16,7.0821976 15.938,7.22446427 15.8286667,7.3249976 L8.89533333,13.7249976 C8.7396,13.8697976 8.51306667,13.9072643 8.31933333,13.8219309 C8.12546667,13.7368643 8,13.5452643 8,13.3332643 L8,9.6061976 C2.9328,9.72753094 1.99786667,11.5968643 1.0104,13.5717976 C0.9188,13.7556643 0.731733333,13.8665976 0.533333333,13.8665976 Z M8.53333333,8.53326427 C8.82813333,8.53326427 9.06666667,8.7717976 9.06666667,9.0665976 L9.06666667,12.1149976 L14.6802667,6.93326427 L9.06666667,1.75153094 L9.06666667,4.79993094 C9.06666667,5.09473094 8.82813333,5.33326427 8.53333333,5.33326427 C2.6416,5.33326427 1.37293333,7.61193094 1.12346667,11.2515309 C2.22813333,9.76766427 4.11093333,8.53326427 8.53333333,8.53326427 Z'
        id='Share'
      ></path>
    </svg>
  )
}

export default Share
