import * as React from 'react'

function Info(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='24px' height='24px' viewBox='0 0 512 512' fill='currentColor' {...props}>
      <path
        d='M256,0 C114.509,0 0,114.496 0,256 C0,397.489 114.496,512 256,512 C397.491,512 512,397.504 512,256 C512,114.511 397.504,0 256,0 Z M256,476.279 C134.538,476.279 35.721,377.463 35.721,256 C35.721,134.537 134.538,35.721 256,35.721 C377.462,35.721 476.279,134.537 476.279,256 C476.279,377.463 377.462,476.279 256,476.279 Z'
        id='Shape'
      ></path>
      <path
        d='M256.006,213.397 C240.842,213.397 230.059,219.801 230.059,229.236 L230.059,357.622 C230.059,365.71 240.842,373.796 256.006,373.796 C270.496,373.796 282.289,365.71 282.289,357.622 L282.289,229.234 C282.289,219.8 270.496,213.397 256.006,213.397 Z'
        id='Path'
      ></path>
      <path
        d='M256.006,134.208 C240.505,134.208 228.375,145.328 228.375,158.133 C228.375,170.939 240.506,182.396 256.006,182.396 C271.17,182.396 283.302,170.939 283.302,158.133 C283.302,145.328 271.169,134.208 256.006,134.208 Z'
        id='Path'
      ></path>
    </svg>
  )
}

export default Info
