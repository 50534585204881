import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Text } from '@chakra-ui/layout'
import { Box } from '../Box'
import Search from '../../icons/Search'
import { MenuValue } from '../../types'
import Close from '../../icons/Close'
import { Input } from '../Input'
import { IconButton } from '../Button'
import { ChakraProps, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { SearchMenu, Share } from '../../icons'
import Image from 'next/image'

/* eslint-disable */
export type MobileHeaderProps = {
  headerMenu: { [key: string]: MenuValue }
  onSwitch?: (top?: boolean) => void
}
/* eslint-enable */

export type MainNavItemProps = ChakraProps &
  React.LiHTMLAttributes<HTMLLIElement> & {
    url: string
    name: string
  }

export const MainNavItem = (props: MainNavItemProps) => {
  const { url, name, ...otherProps } = props
  return (
    <Box as='li' {...(otherProps as any)} display='inline-flex' width={'33.3333%'} py='9px' userSelect='none'>
      <Link
        href={url}
        onDragStart={e => {
          e.preventDefault()
        }}
        prefetch={false}
      >
        <Text
          as='span'
          textStyle={'mobileNavLink'}
          _hover={{ color: 'red' }}
          textTransform='uppercase'
          fontSize={'16px'}
        >
          {name}
        </Text>
      </Link>
    </Box>
  )
}

export function MobileHeader(props: MobileHeaderProps) {
  const { headerMenu, onSwitch } = props
  const [open, setOpen] = React.useState(false)
  const [searchVal, setSearch] = React.useState('')
  const router = useRouter()
  const searchFieldRef = React.useRef<HTMLInputElement>(null)

  const [menu, setMenu] = useState<MainNavItemProps[]>([])
  const [sticky, setSticky] = useState<{ top: number | 'auto'; bottom: number | 'auto' }>({
    top: 0,
    bottom: 'auto'
  })
  const [logoImg, setLogoImg] = useState<{ width: number; height: number; url: string }>({
    width: 73,
    height: 16,
    url: 'https://static.letschuhai.com/static/images/logo.png'
  })
  const [isWechat, setIsWechat] = useState<boolean>(false)
  const [showWechatShare, setShowWechatShare] = useState<boolean>(false)

  React.useEffect(() => {
    setOpen(false)
    setSticky({ top: 0, bottom: 'auto' })

    const handleOnScroll = () => {
      const scrollTop = window.pageYOffset

      if (scrollTop > 400) {
        setSticky({ top: 'auto', bottom: 0 })
        if (onSwitch) {
          onSwitch(true)
        }
        setLogoImg({
          width: 139,
          height: 16,
          url: 'https://static.letschuhai.com/static/images/letschuhai_logo.png'
        })
      }

      if (scrollTop <= 400) {
        setSticky({ top: 0, bottom: 'auto' })
        if (onSwitch) {
          onSwitch(false)
        }
        setLogoImg({
          width: 73,
          height: 16,
          url: 'https://static.letschuhai.com/static/images/logo.png'
        })
      }
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleOnScroll)

      if (navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1) {
        setIsWechat(true)
      }
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', handleOnScroll)
        setIsWechat(false)
      }
    }
  }, [router.pathname, router.query])

  useEffect(() => {
    if (headerMenu) {
      const menuItems = Object.keys(headerMenu).map(m => {
        const menu = headerMenu[m]
        return {
          name: menu.title,
          url: menu.link
        }
      })
      setMenu(menuItems)
    }

    return () => {
      setMenu([])
    }
  }, [headerMenu])

  const changeSearchVal = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const doSearch = (e: React.FormEvent) => {
    if (!searchVal) {
      return
    }
    e.preventDefault()
    router.push(`/search/${encodeURI(searchVal)}`)
  }

  return (
    <Box width={'100vw'} height={'50px'}>
      <Box
        position={'fixed'}
        top={sticky.top}
        bottom={sticky.bottom}
        background={'var(--chakra-colors-bg)'}
        width={'100vw'}
        zIndex={999}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        borderBottom={sticky.top === 0 ? '1px solid rgb(102, 252, 241)' : 'none'}
        borderTop={sticky.top !== 0 ? '1px solid rgb(102, 252, 241)' : 'none'}
        padding={'16px'}
      >
        <Link href='/' prefetch={false}>
          <Box width={`${logoImg.width}px`} height={`${logoImg.height}px`}>
            <Image
              src={`${logoImg.url}`}
              width={`${logoImg.width}px`}
              height={`${logoImg.height}px`}
              objectFit='cover'
              alt='36氪出海'
            />
          </Box>
        </Link>

        {/** Actions */}
        <Box display={'flex'}>
          {isWechat ? (
            <IconButton
              width='24px'
              height='24px'
              color='rgb(102, 252, 241)'
              onClick={() => {
                setShowWechatShare(true)
              }}
              fontSize='24px'
              variant='svg'
              aria-label='Share on Wechat'
              display={'flex'}
              alignItems='center'
              justifyContent={'center'}
            >
              <Share />
            </IconButton>
          ) : null}
          <IconButton
            width='24px'
            height='24px'
            color='rgb(102, 252, 241)'
            onClick={() => {
              setOpen(true)
            }}
            fontSize='24px'
            variant='svg'
            aria-label='Toggle Menu'
            marginLeft={'12px'}
          >
            <SearchMenu />
          </IconButton>
        </Box>
      </Box>
      <Modal
        isOpen={open}
        onClose={() => {
          setOpen(false)
        }}
        // size={'full'}
      >
        <ModalOverlay />
        <ModalContent background={'#efefef'} width={'100vw'} marginTop={'0'} maxWidth={'100vw'} borderRadius={'0'}>
          <IconButton
            width='40px'
            height='40px'
            color='#333'
            // bg='rgb(102, 252, 241)'
            borderRadius='50%'
            border={'2px solid #333'}
            onClick={() => {
              setOpen(false)
            }}
            fontSize='32px'
            variant='svg'
            aria-label='Close search'
            position={'absolute'}
            top={'16px'}
            right={'16px'}
          >
            <Close />
          </IconButton>
          <ModalBody padding={'64px 24px'}>
            <Box>
              <Box
                as='img'
                height={'32px'}
                src={'https://static.letschuhai.com/static/images/logo-red.png'}
                alt='36氪出海'
              />
            </Box>
            <Box as='form' mx='auto' onSubmit={doSearch} my={'32px'}>
              <Box position='relative' display={'flex'} alignItems={'center'}>
                <Input
                  ref={searchFieldRef}
                  pr='64px'
                  aria-label='搜索'
                  variant='borderlessDesktopHeader'
                  name='search'
                  placeholder='请输入关键词'
                  _hover={{
                    borderColor: 'var(--chakra-colors-blue-500)',
                    color: 'rgba(0,0,0,0.7) !important'
                  }}
                  _placeholder={{
                    color: 'rgba(0,0,0,0.6)'
                  }}
                  color='rgba(0,0,0,0.7)'
                  onChange={changeSearchVal}
                  border={'1px solid #ccc'}
                  borderRadius={'4px'}
                  fontSize={'16px'}
                  enterKeyHint={'search'}
                  _autofill={{
                    WebkitBoxShadow: '0 0 0 1000px #fff inset',
                    WebkitTextFillColor: '#000 !important',
                    color: '#000'
                  }}
                />
                <IconButton
                  onClick={doSearch}
                  fontSize='20px'
                  color='#fff'
                  position='absolute'
                  right='2px'
                  // bottom='2px'
                  variant='svg'
                  aria-label='Search'
                  width={'64px'}
                  height={'40px'}
                  background={searchVal !== '' ? 'var(--chakra-colors-blue-500)' : 'rgba(0,0,0,0.2)'}
                  borderRadius={'4px'}
                  transition={'ease-in-out .3s all'}
                >
                  <Search />
                </IconButton>
              </Box>
            </Box>
            <Box flex='1' as='ul'>
              {menu.map((l, index) => {
                return <MainNavItem name={l.name} url={l.url} key={index} />
              })}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      {showWechatShare ? (
        <Box
          position='fixed'
          width={'100vw'}
          height={'100vh'}
          top={0}
          left={0}
          zIndex={99999}
          onClick={() => {
            setShowWechatShare(false)
          }}
        >
          <Box
            as='img'
            width={'100vw'}
            height={'100vh'}
            src={'https://static.letschuhai.com/static/images/wechat-share.png'}
            alt='36氪出海'
          />
        </Box>
      ) : null}
    </Box>
  )
}
