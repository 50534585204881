import { Layout, Promotion, Collection, Section, MenuValue, GlobalSettings } from '../types'
import { fetcher, fetchPromotion, fetchMenu, cacheStore, FetcherConfig, fetchPage, defaultFetcherConfig } from './'

type SectionsResult = { section: Layout; data?: Collection | Section }[]

export const fetchHomeData = async () => {
  let error = false
  let result: SectionsResult = []
  let promotions: { [key: string]: Promotion[] } = {}
  let headerMenu: { [key: string]: MenuValue } = {}
  let seo = ''
  const defaultFetcherConfig: FetcherConfig = {
    cache: cacheStore
  }

  try {
    const responses = await Promise.all([
      fetcher(fetchPromotion(), defaultFetcherConfig),
      fetcher(fetchPage({ slug: 'new-home-page' }), defaultFetcherConfig),
      fetcher(fetchMenu(), defaultFetcherConfig)
    ])

    promotions = responses[0]

    headerMenu = responses[2]['primary-menu']?.menu || {}

    const sectionResult = responses[1]

    seo = sectionResult.yoast_head

    result = sectionResult.sections.map(s => {
      // console.log('=======================s: ', JSON.stringify(s), ' index: ', JSON.stringify(data[index]))
      return {
        section: s.section,
        data: s.data
      }
    })
    // .filter(d => {
    //   console.log('========================d:', JSON.stringify(d))
    //   return d.data
    // })

    // console.log('result: ', JSON.stringify(result))
  } catch (e) {
    // eslint-disable-next-line no-console
    // console.error(e)
    error = true
  }

  return {
    seo,
    headerMenu,
    promotions,
    result,
    error
  }
}

// Set GlobalSettings as the promise return type for fetchGlobalSettings
export const fetchGlobalSettings = (): Promise<GlobalSettings> => {
  return fetcher(`${process.env.NEXT_PUBLIC_API_HOST}/v2/settings`, defaultFetcherConfig)
}
