import React from 'react'
import { Box } from '../Box'
import Image from 'next/image'

export function WeChatPop() {
  return (
    <Box display='flex'>
      <Box bg='white' width='125px' height='125px' display='flex' alignItems='center' justifyContent='center'>
        <Image src='https://static.letschuhai.com/static/images/qr.jpeg' width={125} height={125} />
      </Box>
      <Box ml='15px' pt='10px'>
        <Box as='p'>
          欢迎扫码添加出海小助手微信
          <br />
          <br />
          <Box as='span' fontWeight='600'>
            ID：wow36krchuhai-xzs
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
