import * as React from 'react'

function Close(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' fill='currentColor' {...props}>
      <path d='M8.5 7.5l8.485 8.485-1.06 1.06-8.486-8.484z' />
      <path d='M17 8.5l-8.485 8.485-1.06-1.06 8.484-8.486z' />
    </svg>
  )
}

export default Close
