import { Box, BoxProps, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react'
import { ReactNode, forwardRef, useEffect, useState } from 'react'
import { Qrcode, ToTop } from '../../icons'
import { Link } from '../Link'
import { AsideQrcode } from '../AsideQrcode'
import HandShake from '../../icons/Shake'

interface WidgetProps extends BoxProps {
  icon: ReactNode
  text: string
  url?: string
  shortText?: string
}

const StickyWidget = forwardRef<HTMLDivElement, WidgetProps>((props, ref) => {
  const { icon, text, url, shortText, ...rest } = props
  return (
    <Box {...rest} ref={ref} className='aside-widget'>
      {props?.url && <Link href={url} className='aside-widget__link' target='_blank' />}
      <Box className='aside-widget__default'>
        <Box className='aside-widget__icon'>{icon}</Box>
        <Box className='aside-widget__text'>{shortText}</Box>
      </Box>
      <Box className='aside-widget__active'>{text}</Box>
    </Box>
  )
}) as React.FC<WidgetProps>

StickyWidget.displayName = 'StickyWidget'

export const StickyWidgets = () => {
  const [showQrcode, setShowQrcode] = useState<boolean>(false)
  const [showCooperation, setShowCooperation] = useState<boolean>(false)

  //close popover when click outside or scroll
  const closePopover = () => {
    setShowQrcode(false)
    setShowCooperation(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', closePopover)
    return () => {
      window.removeEventListener('scroll', closePopover)
    }
  }, [])

  return (
    <Box position={'fixed'} right={'8px'} bottom={'160px'} zIndex={3}>
      <Popover isOpen={showCooperation} placement='left'>
        <PopoverTrigger>
          <StickyWidget
            icon={<HandShake width={'24px'} height={'24px'} />}
            text={'寻求合作'}
            shortText='合作'
            onMouseOver={() => {
              setShowCooperation(true)
              setShowQrcode(false)
            }}
            cursor={'pointer'}
          />
        </PopoverTrigger>

        <PopoverContent zIndex={3}>
          <AsideQrcode isPopover={true} isSingle={true} isCooperation={true} />
        </PopoverContent>
      </Popover>

      <Popover isOpen={showQrcode} placement='left'>
        <PopoverTrigger>
          <StickyWidget
            icon={<Qrcode width={'16px'} height={'16px'} />}
            text={'加入社群'}
            shortText='社群'
            onMouseOver={() => {
              setShowQrcode(true)
              setShowCooperation(false)
            }}
            cursor={'pointer'}
          />
        </PopoverTrigger>

        <PopoverContent zIndex={3}>
          <AsideQrcode isPopover={true} isSingle={true} isCooperation={false} />
        </PopoverContent>
      </Popover>

      <StickyWidget
        icon={<ToTop width={'16px'} height={'16px'} />}
        text={'返回顶部'}
        shortText='顶部'
        cursor={'pointer'}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        onMouseOver={() => {
          setShowQrcode(false)
          setShowCooperation(false)
        }}
      />
    </Box>
  )
}
