"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchRelatedPostUrls = exports.fetchViewPost = exports.fetchPreviewPostById = exports.fetchPostById = void 0;
var fetchPostById = function (id) {
    return "".concat(process.env.NEXT_PUBLIC_API_HOST, "/v2/posts/").concat(id);
};
exports.fetchPostById = fetchPostById;
var fetchPreviewPostById = function (id) {
    return "".concat(process.env.NEXT_PUBLIC_API_HOST, "/v2/posts/preview/").concat(id);
};
exports.fetchPreviewPostById = fetchPreviewPostById;
var fetchViewPost = function () {
    return "".concat(process.env.NEXT_PUBLIC_VIEW_API_HOST, "/v2/views");
};
exports.fetchViewPost = fetchViewPost;
var fetchRelatedPostUrls = function (currentPost) {
    var maxPosts = 5;
    var urls = [];
    var taxonomies = currentPost.taxonomies;
    var topic = taxonomies.topic, industry = taxonomies.industry, market = taxonomies.market;
    var numberOfTopics = topic.length;
    var numberOfIndustry = industry === null || industry === void 0 ? void 0 : industry.length;
    var numberOfMarket = market === null || market === void 0 ? void 0 : market.length;
    // fetch 1 post on first industry if have
    if (numberOfIndustry) {
        urls.push("".concat(process.env.NEXT_PUBLIC_API_HOST, "/v2/posts?industry=").concat(industry[0].term_id, "&exclude[]=").concat(currentPost.id, "&per_page=1"));
        maxPosts--;
    }
    // fetch 1 post on first market if have
    if (numberOfMarket) {
        urls.push("".concat(process.env.NEXT_PUBLIC_API_HOST, "/v2/posts?market=").concat(market[0].term_id, "&exclude[]=").concat(currentPost.id, "&per_page=1"));
        maxPosts--;
    }
    // fetch other posts on each topic
    var perPageEachTopic = Math.ceil(maxPosts / numberOfTopics);
    var remainPosts = maxPosts % numberOfTopics;
    if (!(topic === null || topic === void 0 ? void 0 : topic.length)) {
        return urls;
    }
    topic.forEach(function (t, index) {
        var perPage = remainPosts ? perPageEachTopic + 1 : perPageEachTopic;
        if (index === 0) {
            // fetch 3 more posts on first topic then we can filter to make 5 posts are unique
            perPage += 3;
        }
        urls.push("".concat(process.env.NEXT_PUBLIC_API_HOST, "/v2/posts?topic=").concat(t.term_id, "&exclude[]=").concat(currentPost.id, "&per_page=").concat(perPage));
        remainPosts--;
    });
    return urls;
};
exports.fetchRelatedPostUrls = fetchRelatedPostUrls;
