import * as React from 'react'

function Recent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M23.267 6.83977C22.7352 7.10568 22.4989 7.74091 22.7648 8.2875C23.4739 9.76477 23.8432 11.3455 23.8432 13C23.8432 18.9682 18.983 23.8284 13.0148 23.8284C7.04659 23.8284 2.17159 18.9682 2.17159 13C2.17159 7.03182 7.03182 2.17159 13 2.17159C15.4818 2.17159 17.8011 2.98409 19.7216 4.50568C20.1943 4.875 20.8739 4.80114 21.2432 4.32841C21.6125 3.85568 21.5386 3.17614 21.0659 2.80682C18.7909 0.989773 15.925 0 13 0C5.83523 0 0 5.83523 0 13C0 20.1648 5.83523 26 13 26C20.1648 26 26 20.1648 26 13C26 11.0205 25.5716 9.11477 24.7148 7.34205C24.4489 6.81023 23.7989 6.57386 23.267 6.83977Z'
        fill='#66FCF1'
      />
      <path
        d='M13.0003 4.32788C12.4094 4.32788 11.9219 4.81538 11.9219 5.40629V12.9995C11.9219 13.5904 12.4094 14.0779 13.0003 14.0779H18.4219C19.0128 14.0779 19.5003 13.5904 19.5003 12.9995C19.5003 12.4086 19.0128 11.9211 18.4219 11.9211H14.0787V5.42106C14.0787 4.81538 13.5912 4.32788 13.0003 4.32788Z'
        fill='#66FCF1'
      />
    </svg>
  )
}

export default Recent
