"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var envs = {
    apiHost: process.env.NEXT_PUBLIC_API_HOST
};
var config = {
    envs: envs,
    paths: {
        subscribe: '/subscribe',
        contact: '/contact',
        authors: function (page) {
            if (page === void 0) { page = 1; }
            if (page === 1) {
                return "/authors";
            }
            return "/authors/".concat(page);
        },
        signUp: '/sign-up',
        signIn: '/sign-in',
        privacy: '/privacy-policy',
        about: '/about-us',
        forgotPassword: '/forgot-password',
        profile: '/profile/me',
        profileEdit: '/profile/edit',
        authorDetail: function (name, page) {
            if (page === void 0) { page = 1; }
            if (page === 1) {
                return "/author/".concat(name);
            }
            return "/author/".concat(name, "/").concat(page);
        },
        sectionDetail: function (slug, page) {
            if (page === void 0) { page = 1; }
            if (page === 1) {
                return "/sections/".concat(slug);
            }
            return "/sections/".concat(slug, "/").concat(page);
        },
        topicDetail: function (slug, page) {
            if (page === void 0) { page = 1; }
            if (page === 1) {
                return "/topic/".concat(slug);
            }
            return "/topic/".concat(slug, "/").concat(page);
        },
        tagDetail: function (slug, page) {
            if (page === void 0) { page = 1; }
            if (page === 1) {
                return "/tag/".concat(slug);
            }
            return "/tag/".concat(slug, "/").concat(page);
        },
        industryDetail: function (slug, page) {
            if (page === void 0) { page = 1; }
            if (page === 1) {
                return "/industry/".concat(slug);
            }
            return "/industry/".concat(slug, "/").concat(page);
        },
        companyDetail: function (slug, page) {
            if (page === void 0) { page = 1; }
            if (page === 1) {
                return "/company/".concat(slug);
            }
            return "/company/".concat(slug, "/").concat(page);
        },
        recent: function (page) {
            if (page === void 0) { page = 1; }
            if (page === 1) {
                return "/recent";
            }
            return "/recent/".concat(page);
        },
        trending: function (page) {
            if (page === void 0) { page = 1; }
            if (page === 1) {
                return "/trending";
            }
            return "/trending/".concat(page);
        },
        searchResult: function (searchStr, page) {
            if (page === void 0) { page = 1; }
            if (page === 1) {
                return "/search?s=".concat(searchStr);
            }
            return "/search?s=".concat(searchStr, "&page=").concat(page);
        },
        jobs: function (page) {
            if (page === void 0) { page = 1; }
            if (page === 1) {
                return "/jobs";
            }
            return "/jobs/".concat(page);
        },
        jobDetail: function (title, page) {
            if (page === void 0) { page = 1; }
            if (page === 1) {
                return "/job/".concat(title);
            }
            return "/job/".concat(title, "/").concat(page);
        },
        marketDetail: function (slug, page) {
            if (page === void 0) { page = 1; }
            if (page === 1) {
                return "/market/".concat(slug);
            }
            return "/market/".concat(slug, "/").concat(page);
        },
        interested: 'http://sg.mikecrm.com/db5vuau'
    },
    apis: {},
    authTokenKey: 'krasia_token'
};
exports.default = config;
