import React from 'react'
import NextLink from 'next/link'
import { Box } from '@chakra-ui/layout'
import { SystemProps } from '@chakra-ui/system'

export type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  SystemProps & {
    href: string
    children?: React.ReactNode
    noStyle?: boolean
  }

export function Link(props: LinkProps) {
  const { noStyle, href, children, ...otherProps } = props

  if (noStyle) {
    return (
      <NextLink href={href} prefetch={false}>
        <Box as='a' href={href} {...(otherProps as any)}>
          {children}
        </Box>
      </NextLink>
    )
  }

  return (
    <NextLink href={href} prefetch={false}>
      <Box
        as='a'
        color='link'
        href={href}
        display='inline-block'
        _hover={{ textDecoration: 'underline' }}
        {...(otherProps as any)}
      >
        {children}
      </Box>
    </NextLink>
  )
}
