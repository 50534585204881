import * as React from 'react'

function Menu(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' fill='currentColor' {...props}>
      <path d='M2 12h20v1H2z' />
      <path d='M2 12h20v1H2zM2 8h20v1H2z' />
      <path d='M2 8h20v1H2zM2 16h20v1H2z' />
      <path d='M2 16h20v1H2z' />
    </svg>
  )
}

export default Menu
