import * as React from 'react'

function ToTop(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='16px' height='16px' viewBox='0 0 95 128' fill='currentColor' {...props}>
      <path d='M47.1579414,20.2105146 C49.6272376,20.2105146 51.7862828,21.5390729 52.9592522,23.5203647 L90.0042325,60.6315439 C92.6162917,63.2591327 92.6162917,67.502897 90.0042325,70.1304858 C88.7070768,71.2770054 87.0183563,71.8799931 85.2885936,71.8147693 C83.4882827,71.8072264 81.7656192,71.0795591 80.5055124,69.7937178 L53.894,43.183 L53.8948536,121.263088 C53.8948536,124.98373 50.878584,128 47.1579414,128 C43.4372988,128 40.4210293,124.98373 40.4210293,121.263088 L40.421,43.182 L13.8105923,69.7937178 C10.7410788,71.89641 6.548118,71.1128366 4.44542582,68.0433231 C2.9233145,65.8214976 2.87073611,62.9068352 4.31165041,60.6315439 L41.3566307,23.5203647 C42.5296001,21.5390729 44.6886453,20.2105146 47.1579414,20.2105146 Z M87.5789707,13.4736025 L6.73691216,13.4736025 C3.01626956,13.4736025 0,10.4573329 0,6.73669031 C0,3.01604771 3.01626956,0 6.73691216,0 L87.5789707,0 C91.2996133,0 94.3158829,3.01626956 94.3158829,6.73691216 C94.3158829,10.4575548 91.2996133,13.4736025 87.5789707,13.4736025 Z'></path>
    </svg>
  )
}

export default ToTop
