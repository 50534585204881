import React from 'react'
import Sticky from 'react-sticky-el'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Flex } from '@chakra-ui/layout'

import { AppContainer } from '../AppContainer'
import { Box, BoxProps } from '../Box'
import { MenuValue } from '../../types'
import { MainNav } from '../MainNav'

import Image from 'next/image'

export type DesktopHeaderProps = {
  headerMenu: { [key: string]: MenuValue }
  links: {
    login: { text: string; url: string }
    subscribe: { text: string; url: string }
  }
  texts?: {
    openMenu: string
    closeMenu: string
    searchPlaceHolder: string
    search: string
  }
}

export function DesktopHeader(props: DesktopHeaderProps) {
  const { headerMenu } = props
  const [open, setOpen] = React.useState(false)
  const router = useRouter()
  const searchFieldRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    setOpen(false)
  }, [router.pathname, router.query])

  React.useEffect(() => {
    if (open && searchFieldRef.current) {
      searchFieldRef.current.focus()
    }
  }, [open, searchFieldRef])

  const handleOpenExpand = () => {
    setOpen(true)
  }

  const headerProps: BoxProps = {
    ...(open
      ? {
          color: 'white',
          width: '100%',
          backgroundColor: '#1F2833',
          zIndex: '999',
          position: 'fixed',
          top: 0,
          left: 0
        }
      : {})
  }

  return (
    <>
      <Flex as='header' bg='transparent' flexDirection='column' {...headerProps}>
        <AppContainer
          pl={{ base: '32px', xl: '85px' }}
          pr={{ base: '32px', xl: '85px' }}
          pt={open ? '50px' : 0}
          pb={{
            base: '28px',
            md: '13px'
          }}
        >
          {!open && (
            <Box
              display='flex'
              justifyContent='space-between'
              mb={'24px'}
              fontSize={'13px'}
              fontWeight={'500'}
              visibility={{
                base: 'hidden',
                md: 'visible'
              }}
              lineHeight={'32px'}
            >
              <h1 style={{ color: '#66FCF1' }}>36氪出海 - 助力中国公司全球化</h1>
              <Box as='ul'>
                <Box as='li' display='inline-block' mr='20px'>
                  <Box
                    as='a'
                    href='http://u.letschuhai.com/ii4GXm'
                    target='_blank'
                    style={{ display: 'block', lineHeight: '30px', color: '#66FCF1', opacity: 0.85 }}
                    _hover={{ textDecoration: 'underline', opacity: ' 1 !important' }}
                    title='英文站 KrASIA'
                  >
                    英文站 KrASIA
                  </Box>
                </Box>
                <Box as='li' display='inline-block'>
                  <Box
                    as='a'
                    href='http://u.letschuhai.com/amJ4Um'
                    target='_blank'
                    style={{ display: 'block', lineHeight: '30px', color: '#66FCF1', opacity: 0.85 }}
                    _hover={{ textDecoration: 'underline', opacity: ' 1 !important' }}
                    title='日文站 KrJapan'
                  >
                    日文站 KrJAPAN
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          <Box display='flex' justifyContent={{ base: 'center', lg: 'center' }} alignItems='flex-end'>
            <Link href='/' prefetch={false}>
              <Box
                as='a'
                href='/'
                title='Home page'
                mb={{
                  base: '30px',
                  lg: '27px'
                }}
              >
                <Image
                  width={175}
                  height={38}
                  src={'https://static.letschuhai.com/static/images/logo.png'}
                  alt='36氪出海'
                />
              </Box>
            </Link>
          </Box>
        </AppContainer>
      </Flex>
      <Sticky>
        <MainNav onOpenMenu={handleOpenExpand} list={headerMenu} />
      </Sticky>
    </>
  )
}
