import * as React from 'react'

function LinkIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 24 24' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeWidth='1.5'
        d='M12.792 15.8l1.43-1.432a6.076 6.076 0 000-8.59 6.067 6.067 0 00-8.583 0L2.778 8.643A6.076 6.076 0 006.732 19'
      ></path>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeWidth='1.5'
        d='M21.222 15.358A6.076 6.076 0 0017.268 5m1.093 13.221a6.067 6.067 0 01-8.583 0 6.076 6.076 0 010-8.589l1.43-1.431'
      ></path>
    </svg>
  )
}

export default LinkIcon
