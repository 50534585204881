import { Box } from '@chakra-ui/react'
import React from 'react'

import { MenuValue } from '../../types'

import { DesktopHeader } from '../DesktopHeader'
import { MobileHeader } from '../MobileHeader'

/* eslint-disable */
export type HeaderProps = {
  headerMenu: { [key: string]: MenuValue }
  links: {
    login: { text: string; url: string }
    subscribe: { text: string; url: string }
  }
  texts?: {
    openMenu: string
    closeMenu: string
    searchPlaceHolder: string
    search: string
  }
  onSwitch?: (top?: boolean) => void
}
/* eslint-enable */

export function Header(props: HeaderProps) {
  return (
    <Box position={'relative'} zIndex={3}>
      <Box display={{ base: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block', '2xl': 'block' }}>
        <DesktopHeader {...props} />
      </Box>
      <Box display={{ base: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none', '2xl': 'none' }}>
        <MobileHeader {...props} />
      </Box>
    </Box>
  )
}
