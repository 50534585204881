import * as React from 'react'

function Compass(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='26px' height='26px' viewBox='0 0 512 512' fill='currentColor' {...props}>
      <path
        d='M437.02,74.981 C388.667,26.629 324.38,0 256,0 C187.62,0 123.333,26.629 74.98,74.981 C26.629,123.333 0,187.62 0,256 C0,324.38 26.629,388.667 74.98,437.019 C123.333,485.371 187.62,512 256,512 C324.38,512 388.667,485.371 437.02,437.019 C485.371,388.667 512,324.38 512,256 C512,187.62 485.371,123.333 437.02,74.981 Z M256,482 C131.383,482 30,380.617 30,256 C30,131.383 131.383,30 256,30 C380.617,30 482,131.383 482,256 C482,380.617 380.617,482 256,482 Z'
        id='Shape'
      ></path>
      <path
        d='M379.498,117.283 L194.534,194.533 L117.284,379.497 L132.503,394.716 L317.467,317.466 L394.717,132.502 L379.498,117.283 Z M294.533,294.534 L162.197,349.804 L217.467,217.467 L349.803,162.197 L294.533,294.534 Z'
        id='Shape'
      ></path>
      <path
        d='M256,236 C244.972,236 236,244.972 236,256 C236,267.028 244.972,276 256,276 C267.028,276 276,267.028 276,256 C276,244.972 267.028,236 256,236 Z'
        id='Path'
      ></path>
    </svg>
  )
}

export default Compass
