import * as React from 'react'

function Email(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 200 158' fill='currentColor' {...props}>
      <path
        d='M81.5535458,93.4775336 C91.7405314,103.754702 108.259699,103.754702 118.447146,93.4775336 L199.74329,20.2381198 C199.866686,21.0836718 200,21.9264557 200,22.8079886 L200,114.036945 L200,140.352613 C200,150.043477 192.213127,157.897007 182.611985,157.897007 L17.3880152,157.897007 C7.78687304,157.897007 0,150.043246 0,140.352613 L0,96.4920897 L0,22.8079886 C0,21.9264559 0.133775344,21.0834412 0.25694072,20.2381198 L81.5535458,93.4775336 Z M189.941254,1.68856644 L111.297551,72.2501687 C102.626608,80.9981475 97.2608362,80.9981475 88.5845886,72.2501687 L9.98170982,1.72962172 C12.2381294,0.650193819 14.7307314,0 17.3880152,0 L182.611754,0 C185.238362,0 187.710898,0.632203327 189.941254,1.68856644 Z'
        id='Shape'
      ></path>
    </svg>
  )
}

export default Email
