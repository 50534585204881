import Link from 'next/link'
import React from 'react'
import { Popover, PopoverTrigger, PopoverContent, PopoverArrow } from '@chakra-ui/popover'
import { Portal } from '@chakra-ui/portal'

import { Button, ButtonProps } from '../Button'
import { Box } from '../Box'
import { AppContainer } from '../AppContainer'
import { WeChatPop } from '../WeChatPop'
import Email from '../../icons/Email'
import Toutiao from '../../icons/Toutiao'
import Wechat from '../../icons/Wechat'
import Image from 'next/image'
import { useMediaQuery } from '@chakra-ui/react'
import { StickyWidgets } from '../StickyWidgets'
// import { GlobalNotification } from '../../types'
// import { fetchGlobalSettings } from '../../fetchers/home'

function Social(props: Partial<ButtonProps> & { href?: string; title?: string }) {
  const { href, title, ...otherProps } = props

  const button = (
    <Button
      width={{
        base: '32px',
        md: '32px'
      }}
      height={{
        base: '32px',
        md: '32px'
      }}
      ml='8px'
      minWidth={0}
      p={0}
      display='inline-flex'
      color='footer.text'
      fontSize={{
        base: '20px',
        md: '24px'
      }}
      textAlign='center'
      borderRadius='8px'
      // backgroundColor='socialBackground'
      variant='unstyled'
      aria-label={title || 'social'}
      role='button'
      {...otherProps}
    />
  )

  if (!href) return button

  return (
    <a href={href} target='_blank' rel='noreferrer' title={''}>
      {button}
    </a>
  )
}

export type FooterLink = { link: string; text: string }

export type FooterProps = {
  texts?: any
  listLink: FooterLink[]
}

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
function Footer(props: FooterProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)', {
    ssr: true,
    fallback: false // return false on the server, and re-evaluate on the client side
  }) // 用于判断是否是移动端

  // const [notifications, setNotifications] = React.useState<GlobalNotification>()

  // useEffect(() => {
  //   fetchGlobalSettings().then(settings => {
  //     if (settings && settings.globalNotification && settings.globalNotification.title) {
  //       setNotifications(settings.globalNotification)
  //     }
  //   })

  //   return () => {
  //     setNotifications(undefined)
  //   }
  // }, [])

  return (
    <Box as='footer' py={!isMobile ? '72px' : '24px'} bgColor='bgFooter' color='textSecondary'>
      {!isMobile && <StickyWidgets />}
      {/* {!isMobile && notifications && <GlobalNotifications notifications={notifications} />} */}
      <AppContainer
        pt={0}
        pb={0}
        pl={{ base: '16px', md: '32px', xl: '85px' }}
        pr={{ base: '16px', md: '32px', xl: '85px' }}
      >
        {!isMobile ? (
          <Box display='flex'>
            <Box display={{ lg: 'flex' }} width='100%'>
              <Box
                width={{ base: '50%', lg: '45%', sm: '100%' }}
                textAlign={{ base: 'center', lg: 'left' }}
                pr={{ lg: '84px' }}
                // borderRight={{ lg: '1px solid #CB2D6F' }}
              >
                <Box
                  lineHeight={{
                    base: '26px',
                    lg: '32px'
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  // justifyContent={'space-between'}
                >
                  <Box as='p' fontSize='18px' fontWeight='600' color='white'>
                    36氪出海
                  </Box>
                  <Box
                    justifyContent={{ base: 'center', lg: 'flex-start' }}
                    as='ul'
                    listStyleType='none'
                    display='flex'
                    mt={{ base: '0', lg: '20px', xl: 0 }}
                    alignItems={{
                      base: 'center',
                      lg: 'left'
                    }}
                    ml={8}
                    fontSize={{
                      base: '20px',
                      lg: '20px'
                    }}
                  >
                    <Box as='li'>
                      <Popover>
                        <PopoverTrigger aria-label='关注微信公众号'>
                          <div>
                            <Social ml='0' title='关注公众号'>
                              <Wechat />
                            </Social>
                          </div>
                        </PopoverTrigger>
                        <Portal>
                          <PopoverContent bg='#CB2D6F' borderRadius='20px' p='15px'>
                            <PopoverArrow />
                            <WeChatPop />
                          </PopoverContent>
                        </Portal>
                      </Popover>
                    </Box>
                    <Box as='li'>
                      <Social href='mailto:hello@global.36kr.com' title='联系我们'>
                        <Email />
                      </Social>
                    </Box>
                    <Box as='li'>
                      <Social
                        href='https://www.toutiao.com/c/user/token/MS4wLjABAAAALrC1PnS_BBG3NYM3flVg5e5Jxyriu8WYPJJrEiNBiyo/'
                        title='关注头条'
                      >
                        <Toutiao />
                      </Social>
                    </Box>
                  </Box>
                </Box>
                <Box
                  fontSize={{
                    base: '18px',
                    lg: '16px'
                  }}
                  lineHeight={{
                    base: '36px',
                    lg: '29px'
                  }}
                  as='p'
                  mt='20px'
                  textAlign={'justify'}
                >
                  36氪出海（letschuhai.com）是关注出海的行业媒体，为企业跨境提供海外咨询及专业服务，同时运营着超万人的出海生态社群。
                </Box>
                <Box
                  fontSize={{
                    base: '18px',
                    lg: '16px'
                  }}
                  lineHeight={{
                    base: '36px',
                    lg: '29px'
                  }}
                  as='p'
                  mt='16px'
                  textAlign={'justify'}
                >
                  寻求报道、内容合作、商务合作等，请发送邮件到
                  <a href='mailto:hello@global.36kr.com'>hello@global.36kr.com</a>，或者扫码添加出海小助手微信沟通详情。
                </Box>
              </Box>
              <Box width={{ lg: '55%' }} pt='10px' display={{ base: 'none', lg: 'block' }}>
                <Box display='flex'>
                  <Box display='flex' flexDirection={'column'} flex={1}>
                    <Box as='p' fontSize='18px' fontWeight='600' color='white' mb={'24px'}>
                      合作伙伴
                    </Box>
                    <Box display={'flex'} flexWrap={'wrap'}>
                      <Box
                        width='auto'
                        height='32px'
                        mb={'24px'}
                        mr={'24px'}
                        as={'a'}
                        target={'_blank'}
                        opacity={'0.75'}
                        transition={'all ease-in-out .3s'}
                        _hover={{ opacity: '1' }}
                        href={'https://www.alibabacloud.com/zh/go_global/go-global'}
                      >
                        <Image
                          src='https://static.letschuhai.com/static/images/logo_alibaba_cloud.png'
                          width={195}
                          height={32}
                          alt={'Alibaba Cloud'}
                        />
                      </Box>
                      <Box
                        width='auto'
                        height='32px'
                        mb={'24px'}
                        mr={'24px'}
                        as={'a'}
                        target={'_blank'}
                        opacity={'0.75'}
                        transition={'all ease-in-out .3s'}
                        _hover={{ opacity: '1' }}
                        href={'https://www.alipayplus.com/'}
                      >
                        <Image
                          src='https://static.letschuhai.com/static/images/logo_alipay_plus.png'
                          width={120}
                          height={32}
                          alt={'Alipay Plus'}
                        />
                      </Box>
                      <Box
                        width='auto'
                        height='32px'
                        mb={'24px'}
                        mr={'24px'}
                        as={'a'}
                        target={'_blank'}
                        opacity={'0.75'}
                        transition={'all ease-in-out .3s'}
                        _hover={{ opacity: '1' }}
                        href={'https://www.lazada.cn'}
                      >
                        <Image
                          src='https://static.letschuhai.com/static/images/logo_lazada.png'
                          width={119}
                          height={32}
                          alt={'Lazada'}
                        />
                      </Box>

                      <Box
                        width='auto'
                        height='32px'
                        mb={'24px'}
                        mr={'24px'}
                        as={'a'}
                        target={'_blank'}
                        opacity={'0.75'}
                        transition={'all ease-in-out .3s'}
                        _hover={{ opacity: '1' }}
                        href={'https://www.tiktokforbusinessoutbound.com'}
                      >
                        <Image
                          src='https://static.letschuhai.com/static/images/logo_tiktok.png'
                          width={112}
                          height={32}
                          alt={'Tiktok'}
                        />
                      </Box>
                      <Box
                        width='auto'
                        height='32px'
                        mb={'24px'}
                        mr={'24px'}
                        as={'a'}
                        target={'_blank'}
                        opacity={'0.75'}
                        transition={'all ease-in-out .3s'}
                        _hover={{ opacity: '1' }}
                        href={'https://www.ninjavan.cn'}
                      >
                        <Image
                          src='https://static.letschuhai.com/static/images/logo_ninjavan.png'
                          width={115}
                          height={32}
                          alt={'Ninjavan'}
                        />
                      </Box>
                      <Box
                        width='auto'
                        height='32px'
                        mb={'24px'}
                        mr={'24px'}
                        as={'a'}
                        target={'_blank'}
                        opacity={'0.75'}
                        transition={'all ease-in-out .3s'}
                        _hover={{ opacity: '1' }}
                        href={'https://www.meetsocial.com/'}
                      >
                        <Image src='/images/logo_meetsocial.png' width={92} height={32} alt={'飞书深诺'} />
                      </Box>
                      <Box
                        width='auto'
                        height='32px'
                        mb={'24px'}
                        mr={'24px'}
                        as={'a'}
                        target={'_blank'}
                        opacity={'0.75'}
                        transition={'all ease-in-out .3s'}
                        _hover={{ opacity: '1' }}
                        href={'https://mp.weixin.qq.com/s/Dln2HAIktrbB4hQtoNS9FA'}
                      >
                        <Image src='/images/logo_snu.png' width={57} height={32} alt={'新加坡国立大学EMBA'} />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    pr='40px'
                    ml='auto'
                    display={{
                      base: 'none',
                      xl: 'block'
                    }}
                  >
                    <Box as='p' fontSize='18px' fontWeight='600' color='white'>
                      36Kr Global 旗下品牌
                    </Box>
                    <Box mt='16px' as='ul' listStyleType='none' fontSize={15} fontWeight={400}>
                      <Box as='li'>
                        <Link href='/' prefetch={false}>
                          36氪出海
                        </Link>
                      </Box>
                      <Box as='li' mt='20px'>
                        <Box as='a' href='https://36kr.jp/' target='_blank' rel='noreferrer'>
                          36Kr Japan
                        </Box>
                      </Box>
                      <Box as='li' mt='20px'>
                        <Box as='a' href='https://kr-asia.com/' target='_blank' rel='noreferrer'>
                          KrASIA
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : null}

        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          flexDirection={{ base: 'column', sm: 'column', md: 'column', lg: 'row' }}
        >
          {!isMobile ? (
            <Box
              display={{ base: 'flex', xl: 'flex' }}
              justifyContent='flex-start'
              alignItems='center'
              mt={{ base: '40px', xl: '40px' }}
              width={'100%'}
            >
              <Box display='flex' alignItems={'center'}>
                <Box bg='white' width='80px' height='80px' display='flex' alignItems='center' justifyContent='center'>
                  <Image
                    src='https://static.letschuhai.com/static/images/qr.jpg'
                    width={80}
                    height={80}
                    alt='关注微信公众号'
                  />
                </Box>
                <Box ml='15px'>
                  <Box as='p'>
                    扫一扫即刻关注
                    <br />
                    <Box as='span' fontWeight={500}>
                      36氪出海微信公众号
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box display='flex' alignItems={'center'} ml={'16px'}>
                <Box bg='white' width='80px' height='80px' display='flex' alignItems='center' justifyContent='center'>
                  <Image
                    src='https://static.letschuhai.com/static/images/qr.jpeg'
                    width={80}
                    height={80}
                    alt='关注微信公众号'
                  />
                </Box>
                <Box ml='15px'>
                  <Box as='p'>
                    沟通商务合作
                    <br />
                    <Box as='span' fontWeight={500}>
                      添加「出海小助手」微信
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : null}

          <Box
            mt={!isMobile ? '50px' : '0'}
            mb={isMobile ? '64px' : '0'}
            display={'flex'}
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems={'center'}
            justifyContent={'right'}
            width={isMobile ? '100%' : '100%'}
            fontSize='13px'
          >
            <Box as='span'>© 北京极境之羽信息科技有限公司</Box>
            {!isMobile && <Box as='span'>｜</Box>}
            <Box as='a' href='https://beian.miit.gov.cn/' target='_blank' rel='noreferrer' cursor='pointer'>
              京ICP备20010238号-2
            </Box>
            {!isMobile && <Box as='span'>｜</Box>}

            <Box
              as='a'
              href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502044070'
              target='_blank'
              rel='noreferrer'
              cursor='pointer'
              display={'flex'}
              alignItems={'center'}
            >
              <Image
                src='https://static.letschuhai.com/static/images/chuhai_copyright.png'
                alt="Chuhai's copyright license logo"
                width={16}
                height={16}
              />{' '}
              京公网安备 11010502044070号
            </Box>
          </Box>
        </Box>
      </AppContainer>
    </Box>
  )
}

export { Footer }
