import * as React from 'react'

function SpotLight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 36' fill='none' {...props}>
      <path d='M5.556 3.763v7.72H18.23v-7.72L15.185 0H8.601L5.556 3.763z' fill='#1067F0' />
      <path d='M24 36l-7.265-22.407h-9.47L0 36h24z' fill='#FBBC06' />
    </svg>
  )
}

export default SpotLight
