import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { Image } from '@chakra-ui/react'
import React from 'react'

interface AsideQrcodeProps {
  isPopover?: boolean
  isSingle?: boolean
  isCooperation?: boolean
}

export const AsideQrcode: React.FC<AsideQrcodeProps> = props => {
  const { isPopover = false, isSingle = false, isCooperation = true } = props

  // const mainColor = isPopover ? '#fff' : '#20353a'
  const wrapperColor = isPopover ? '#fff' : '#20353a'
  const textColor = isPopover ? '#212832' : '#c9c9c9'
  // const wrapperColor = isPopover ? '#f1f2f6' : '#080a0a'
  const mainColor = isPopover ? '#f1f2f6' : '#216a6a'
  const selectedColor = isPopover ? '#212832' : '#fff'
  if (isSingle) {
    if (isCooperation) {
      return (
        <Box className='aside-qrcode' bg={wrapperColor} p={'4px'}>
          <Tabs variant='unstyled'>
            <TabList>
              <Tab
                // bg={wrapperColor}
                color={selectedColor}
                style={{ fontSize: '12px', fontWeight: 'bold' }}
                _selected={{ color: selectedColor, bg: isPopover }}
                width='100%'
              >
                沟通商务合作
              </Tab>
            </TabList>
            <TabPanels bg={mainColor} mt={'4px'} color={textColor}>
              <TabPanel>
                <Box display='flex' alignItems={'center'}>
                  <Box
                    bg='white'
                    width={isPopover ? '100px' : '128px'}
                    height={isPopover ? '100px' : '128px'}
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <Image
                      width={'100%'}
                      height={'100%'}
                      src='/images/qrcode_cooperation.png'
                      alt={'关注公众号'}
                      objectFit={'cover'}
                    />
                  </Box>
                  <Box ml='15px' fontSize={isPopover ? '13px' : '14px'} fontWeight={'bold'} flex={1}>
                    添加商业化小助手Mia微信
                    <br />
                    备注公司及来意
                  </Box>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      )
    }

    return (
      <Box className='aside-qrcode' bg={wrapperColor} p={'4px'}>
        <Tabs variant='unstyled'>
          <TabList>
            <Tab
              // bg={wrapperColor}
              color={selectedColor}
              style={{ fontSize: '12px', fontWeight: 'bold' }}
              _selected={{ color: selectedColor, bg: isPopover }}
              width='100%'
            >
              加入出海社群
            </Tab>
          </TabList>
          <TabPanels bg={mainColor} mt={'4px'} color={textColor}>
            <TabPanel>
              <Box display='flex' alignItems={'center'}>
                <Box
                  bg='white'
                  width={isPopover ? '100px' : '128px'}
                  height={isPopover ? '100px' : '128px'}
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                >
                  <Image
                    src='/images/qrcode_community.png'
                    alt='wechat qrcode'
                    width={'100%'}
                    height={'100%'}
                    objectFit={'cover'}
                  />
                </Box>
                <Box ml='15px' fontSize={isPopover ? '13px' : '14px'} fontWeight={'bold'} flex={1}>
                  13000+出海人在这里聚集
                  <br />
                  添加出海小助手微信
                  <br />
                  发送名片信息审核入群
                </Box>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    )
  }

  return (
    <Box className='aside-qrcode' bg={wrapperColor} p={'4px'}>
      <Tabs variant='unstyled'>
        <TabList>
          <Tab
            // bg={wrapperColor}
            color={textColor}
            style={{ fontSize: '12px', fontWeight: 'bold' }}
            _selected={{ color: selectedColor, bg: mainColor }}
            width='50%'
          >
            关注公众号
          </Tab>
          <Tab
            // bg={wrapperColor}
            color={textColor}
            style={{ fontSize: '12px', fontWeight: 'bold' }}
            _selected={{ color: selectedColor, bg: mainColor }}
            width='50%'
          >
            加入出海社群
          </Tab>
        </TabList>
        <TabPanels bg={mainColor} mt={'4px'} color={textColor}>
          <TabPanel>
            <Box display='flex' alignItems={'center'}>
              <Box
                bg='white'
                width={isPopover ? '100px' : '128px'}
                height={isPopover ? '100px' : '128px'}
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <Image
                  width={'100%'}
                  height={'100%'}
                  src='/images/qrcode_follow_wechat.png'
                  alt={'关注公众号'}
                  objectFit={'cover'}
                />
              </Box>
              <Box ml='15px' fontSize={isPopover ? '13px' : '14px'} fontWeight={'bold'} flex={1}>
                扫一扫即刻关注
                <br />
                36氪出海微信公众号
              </Box>
            </Box>
          </TabPanel>
          <TabPanel>
            <Box display='flex' alignItems={'center'}>
              <Box
                bg='white'
                width={isPopover ? '100px' : '128px'}
                height={isPopover ? '100px' : '128px'}
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <Image
                  src='/images/qrcode_community.png'
                  alt='wechat qrcode'
                  width={'100%'}
                  height={'100%'}
                  objectFit={'cover'}
                />
              </Box>
              <Box ml='15px' fontSize={isPopover ? '13px' : '14px'} fontWeight={'bold'} flex={1}>
                13000+出海人在这里聚集
                <br />
                添加出海小助手微信
                <br />
                发送名片信息审核入群
              </Box>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}
