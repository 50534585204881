import React from 'react'
import {
  Button as ButtonCore,
  ButtonProps as ButtonCoreProps,
  IconButton,
  IconButtonProps as SA
} from '@chakra-ui/button'
export type ButtonProps = ButtonCoreProps

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { ...otherProps } = props
  return <ButtonCore ref={ref} {...otherProps} />
})

Button.displayName = 'Button'

export type IconButtonProps = SA

export { IconButton }
