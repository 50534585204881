"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchCampaigns = void 0;
var fetchers_1 = require("../fetchers");
var url = 'https://api.mailerlite.com/api';
var fetchCampaigns = function (_a, cacheStore) {
    var page = _a.page, perPage = _a.perPage;
    var offset = (page - 1) * perPage;
    return (0, fetchers_1.fetcher)("".concat(url, "/v2/campaigns/sent?limit=").concat(perPage, "&offset=").concat(offset), {
        cache: cacheStore,
        headers: {
            'Content-Type': 'application/json',
            'X-MailerLite-ApiKey': process.env.NEXT_PUBLIC_MAILERLITE_API_KEY
        }
    });
};
exports.fetchCampaigns = fetchCampaigns;
