import * as React from 'react'

function SearchMenu(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='16px' height='14px' viewBox='0 0 16 14' fill='currentColor' {...props}>
      <path
        d='M12.9566933,9.00266667 C13.5425923,8.26131435 13.8629935,7.34491656 13.8666667,6.4 C13.8666667,4.04358507 11.9564149,2.13333333 9.6,2.13333333 C7.24358507,2.13333333 5.33333333,4.04358507 5.33333333,6.4 C5.33333333,8.75641493 7.24358507,10.6666667 9.6,10.6666667 C10.5449166,10.6629935 11.4613143,10.3425923 12.2026667,9.75669333 L15.0898133,12.6437333 C15.2990821,12.8458518 15.6317252,12.8429613 15.8374499,12.6372366 C16.0431746,12.4315119 16.0460652,12.0988687 15.8439467,11.8896 L12.9566933,9.00266667 Z M6.4,6.4 C6.4,4.6326888 7.8326888,3.2 9.6,3.2 C11.3673112,3.2 12.8,4.6326888 12.8,6.4 C12.8,8.1673112 11.3673112,9.6 9.6,9.6 C7.83349298,9.59806004 6.40193996,8.16650702 6.4,6.4 L6.4,6.4 Z'
        id='Shape'
      ></path>
      <path
        d='M15.4666667,0 L0.533333333,0 C0.238781467,0 0,0.238781467 0,0.533333333 C0,0.8278852 0.238781467,1.06666667 0.533333333,1.06666667 L15.4666667,1.06666667 C15.7612185,1.06666667 16,0.8278852 16,0.533333333 C16,0.238781467 15.7612185,0 15.4666667,0 Z'
        id='Path'
      ></path>
      <path
        d='M0.533333333,7.46666667 L3.73333333,7.46666667 C4.0278852,7.46666667 4.26666667,7.2278852 4.26666667,6.93333333 C4.26666667,6.63878147 4.0278852,6.4 3.73333333,6.4 L0.533333333,6.4 C0.238781467,6.4 0,6.63878147 0,6.93333333 C0,7.2278852 0.238781467,7.46666667 0.533333333,7.46666667 Z'
        id='Path'
      ></path>
      <path
        d='M0.533333333,10.6666667 L3.73333333,10.6666667 C4.0278852,10.6666667 4.26666667,10.4278852 4.26666667,10.1333333 C4.26666667,9.83878147 4.0278852,9.6 3.73333333,9.6 L0.533333333,9.6 C0.238781467,9.6 0,9.83878147 0,10.1333333 C0,10.4278852 0.238781467,10.6666667 0.533333333,10.6666667 Z'
        id='Path'
      ></path>
      <path
        d='M0.533333333,13.8666667 L12.2666667,13.8666667 C12.5612185,13.8666667 12.8,13.6278852 12.8,13.3333333 C12.8,13.0387815 12.5612185,12.8 12.2666667,12.8 L0.533333333,12.8 C0.238781467,12.8 0,13.0387815 0,13.3333333 C0,13.6278852 0.238781467,13.8666667 0.533333333,13.8666667 Z'
        id='Path'
      ></path>
      <path
        d='M0.533333333,4.26666667 L3.73333333,4.26666667 C4.0278852,4.26666667 4.26666667,4.0278852 4.26666667,3.73333333 C4.26666667,3.43878147 4.0278852,3.2 3.73333333,3.2 L0.533333333,3.2 C0.238781467,3.2 0,3.43878147 0,3.73333333 C0,4.0278852 0.238781467,4.26666667 0.533333333,4.26666667 Z'
        id='Path'
      ></path>
    </svg>
  )
}

export default SearchMenu
