"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Columns = exports.Column = exports.ColumnsContext = void 0;
var layout_1 = require("@chakra-ui/layout");
var react_1 = __importStar(require("react"));
exports.ColumnsContext = react_1.default.createContext({
    collapse: '',
    gutter: '8px'
});
function Column(props) {
    var _a, _b;
    var children = props.children, fWidth = props.fWidth, otherProps = __rest(props, ["children", "fWidth"]);
    var _c = (0, react_1.useContext)(exports.ColumnsContext), gutter = _c.gutter, collapse = _c.collapse;
    return (react_1.default.createElement(layout_1.Box, __assign({ _notFirst: {
            paddingTop: collapse ? (_a = { base: gutter }, _a[collapse] = 0, _a) : 0
        }, flexBasis: fWidth ? fWidth : undefined, flexGrow: fWidth ? 0 : undefined, flexShrink: fWidth ? 0 : undefined, minWidth: 0 }, otherProps),
        react_1.default.createElement(layout_1.Box, { display: 'flex', height: '100%', paddingLeft: collapse ? (_b = { base: 0 }, _b[collapse] = gutter, _b) : gutter }, children)));
}
exports.Column = Column;
var Columns = function (_a) {
    var _b, _c;
    var collapse = _a.collapse, children = _a.children, _d = _a.gutter, gutter = _d === void 0 ? '8px' : _d, otherProps = __rest(_a, ["collapse", "children", "gutter"]);
    var gutterCalc = Array.isArray(gutter)
        ? []
        : [typeof gutter === 'number' ? "".concat(gutter * -1, "px") : "-".concat(gutter)];
    if (Array.isArray(gutter)) {
        gutterCalc = gutter.reduce(function (s, g) {
            if (typeof g === 'number') {
                return __spreadArray(__spreadArray([], s, true), ["".concat(g * -1, "px")], false);
            }
            return __spreadArray(__spreadArray([], s, true), ["-".concat(g)], false);
        }, []);
    }
    return (react_1.default.createElement(layout_1.Box, __assign({ display: collapse ? __assign({ base: 'block' }, (_b = {}, _b[collapse] = 'flex', _b)) : 'flex', marginLeft: collapse ? __assign({ base: '0' }, (_c = {}, _c[collapse] = gutterCalc, _c)) : gutterCalc }, otherProps),
        react_1.default.createElement(exports.ColumnsContext.Provider, { value: {
                collapse: collapse,
                gutter: gutter
            } }, children)));
};
exports.Columns = Columns;
